import React from 'react';
import {
  Box,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import BookForm from './BookForm';

const BookNow = ({ onClose, carName, imageUrl, id }) => {
  return (
    <Box height="90vh">
      <Modal isOpen={true} onClose={onClose}  size="5xl" isCentered blockScrollOnMount={false} trapFocus={false}>
        <ModalOverlay />
        <ModalContent borderRadius="xl" mx={{ base: '2.5', lg: '16' }} overflow="hidden" bgColor={"#171923"} >
          <ModalCloseButton top="0" right="0" size="lg" borderRadius="none" borderBottomLeftRadius="md" />
          <ModalBody px={{ base: '3', md: '9', lg: '12' }} py={{ base: '5', md: '6', lg: '8' }} pb={{ base: '6' }}>
            <Stack spacing="6">

          
            <BookForm  carName={carName} imageUrl={imageUrl} id={id}/>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default BookNow;
