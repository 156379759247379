import { ChevronDownIcon } from '@chakra-ui/icons'
import { Menu, MenuButton, MenuList, MenuItem ,Button} from '@chakra-ui/react'
import React from 'react'


const FilterMenu = ({ onSelectFilter, selectedFilter }) => {
  const filterOptions = ['All', 'Stickers', 'Murals'];

  return (
    <Menu>
      <MenuButton as={Button} textAlign={'left'} rightIcon={<ChevronDownIcon />}>
        {selectedFilter}
      </MenuButton>
      <MenuList>
        {filterOptions.map((filter) => (
          <MenuItem
            key={filter}
            onClick={() => onSelectFilter(filter)}
            isDisabled={filter === selectedFilter} // Disable the selected filter
          >
            {filter}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default FilterMenu;


