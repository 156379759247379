import React, { useState, useRef } from 'react';
import { Box, Button, Heading, Stack, Text } from '@chakra-ui/react';
import { ProductCard } from './ProductCard';
import { products } from './_data';
import { ProductGrid } from './ProductGrid';
import FilterMenu from './FilterMenu';
import { Link, Element } from 'react-scroll';


const ListStickers = () => {

  const [selectedFilter, setSelectedFilter] = useState('All');
  const [filteredProducts, setFilteredProducts] = useState(products);
  const itemsPerPage = 6; // Number of items per page
  const [currentPage, setCurrentPage] = useState(1); // Current page

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);

  const listCarRef = useRef(null);


  // Filter the products based on the selected filter
  const filterProducts = (filter) => {
    if (filter === 'All') {
      setFilteredProducts(products);
    } else {
      const filtered = products.filter((product) => product.CarName === filter);
      setFilteredProducts(filtered);
    }
    setSelectedFilter(filter);
    setCurrentPage(1); // Reset current page when filter changes
  };

  // Calculate the range of products to display on the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentProducts = filteredProducts.slice(startIndex, endIndex);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    listCarRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Element name="ListStickers">
        <Box   ref={listCarRef} maxW="7xl" mx="auto" px={{ base: '4', md: '8', lg: '12' }} py={{ base: '6', md: '8', lg: '12' }} spacing={8}>
          <Stack spacing={0} mb="8" gap={8} justifyContent="space-between" direction={{ base: 'column', md: 'row' }}>
            <Stack flex={3}>
              <Heading className="Popk" mb="2" fontSize={{ base: '2xl', sm: '4xl' }}>
                Stickers & Murals
              </Heading>
              <Text className="Pop" textAlign="left" maxW="4xl">
                We take pride in offering a wide range of top-quality Stickers and Murals to suit all your needs.
              </Text>
            </Stack>

            <Stack flex={1} align="left" maxW="xl">
              <FilterMenu onSelectFilter={filterProducts} selectedFilter={selectedFilter} />
            </Stack>
          </Stack>

          <ProductGrid>
            {currentProducts.map((product) => (
              <ProductCard key={product.id} product={product} />
            ))}
          </ProductGrid>

          <Stack direction="row" spacing={2} justifyContent="center" mt={4}>
            {Array.from({ length: totalPages }, (_, i) => (
              <Button
                key={i}
                onClick={() => handlePageChange(i + 1)}
                variant={currentPage === i + 1 ? 'solid' : 'outline'}
              >
                {i + 1}
              </Button>
            ))}
          </Stack>
        </Box>
    </Element>
  );
};

export default ListStickers

