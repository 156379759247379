import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Image,
  useBreakpointValue,
  useColorModeValue,
  Container
} from '@chakra-ui/react';
import * as React from 'react';
import { FiMenu, FiX } from 'react-icons/fi';
import MobileMenu from './MobileMenu';
import { Link, useLocation  } from 'react-router-dom';
import Logo from './gbArt.svg';
import LogoWhite from './gbArtWhite.svg';
import { Link as LinkScroll } from 'react-scroll';
import { CloseIcon, MoonIcon, SunIcon } from "@chakra-ui/icons";
import { useColorMode } from "@chakra-ui/color-mode";
import { RiAliensFill } from 'react-icons/ri';
import { GiElectric } from 'react-icons/gi';






const Navbar = () => {
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  });

  const { colorMode, toggleColorMode } = useColorMode();


   const location = useLocation();
  const isContactPath = location.pathname === '/contact';


  const [isScrolled, setIsScrolled] = React.useState(false);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const bgColor =
  isScrolled && colorMode === 'dark'
    ?  'rgba(26, 32, 44, 0.8)' // Adjust the RGB values and the alpha value for the desired color and transparency
    : isScrolled
    ? 'rgba(255, 255, 255, 0.8)'
    : 'transparent';

  return (
    <Box
      as="nav"
      position="sticky"
      top="0"
      zIndex="100"
      // bg={isScrolled ? 'rgba(255, 255, 255, 0.8)' : 'transparent'}
      // bg={isScrolled && colorMode === 'dark' ? 'teal.900' : isScrolled ? 'rgba(255, 255, 255, 0.8)' : 'transparent'}
      bg={bgColor}

      backdropFilter={isScrolled ? 'blur(4px)' : 'none'}
      transition="all 0.3s ease"
      boxShadow={isScrolled ? 'sm' : 'none'}
    >
      <Container as="section" maxW="7xl" py={{ base: '3', lg: '4' }}>
        <Flex justify="space-between" align="center">
          
          <HStack spacing={4}>
            <Link to="/" aria-label="Home">
              <Image src={colorMode === 'dark' ? LogoWhite : Logo} alt="Logo" height="8" width="auto" />
            </Link>
          </HStack>
           {isDesktop ? (
            <HStack spacing={4}>
              {!isContactPath && (
                <>
                  {/* <LinkScroll to="NosMetiers" aria-label="NosMetiers" spy={true} smooth={true} offset={-70} duration={2000}>
                    <Button variant={'solid'} colorScheme={'green'}>
                      Stickers
                    </Button>
                  </LinkScroll>
                  <LinkScroll to="StatsOnBrand" aria-label="StatsOnBrand" spy={true} smooth={true} offset={-70} duration={2000}>
                    <Button variant={'solid'} bgColor={'#2563eb'} color={'#fff'} _hover={{ bg: '#1f51aa' }}>
                      Tags
                    </Button>
                  </LinkScroll> */}
                  <Link to={`/contact`}>
                  <Button color={"white"} bgColor={'#AFB5BF'} _hover={{ bg: '#959AA0' }}>
                    Contact Us
                  </Button>
                  </Link>
                  <Button onClick={() => toggleColorMode()}  >
                    {colorMode === "dark" ? (
                      <GiElectric color="orange.200" size={'24'}  />
                    ) : (
                      <RiAliensFill color="teal.700" size={'24'} />
                    )}
                  </Button>
                </>
              )}
              {isContactPath && (
                <>
                <Link to={`/`}>
                    <Button >
                      <CloseIcon color="green" size={'24'}  />
                  </Button>
                </Link>
                </>
              )}
            </HStack>
          ) : (
            <IconButton
              variant="ghost"
              icon={isMenuOpen ? <FiX fontSize="1.25rem" /> : <FiMenu fontSize="1.25rem" />}
              aria-label="Toggle Menu"
              onClick={handleMenuToggle}
            />
          )}
        </Flex>
        {isMenuOpen && (
          <Box mt={4}>
            <MobileMenu />
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default Navbar;


// import {
//   Avatar,
//   Box,
//   Button,
//   ButtonGroup,
//   Container,
//   Flex,
//   HStack,
//   IconButton,
//   Image,
//   useBreakpointValue,
//   useColorModeValue,
// } from '@chakra-ui/react';
// import * as React from 'react';
// import { FiMenu, FiX } from 'react-icons/fi';
// import MobileMenu from './MobileMenu';
// import { Link } from 'react-router-dom';
// import Logo from './LogoBelProfVfinal.svg';
// import { Link as LinkScroll } from 'react-scroll';
// import { useLocation } from 'react-router-dom';

// const Navbar = () => {
//   const isDesktop = useBreakpointValue({
//     base: false,
//     lg: true,
//   });

//   const location = useLocation();
//   const isContactOrDirectorPath = location.pathname === '/contact' || location.pathname === '/mot-du-directeur';

//   const [isNavbarFixed, setIsNavbarFixed] = React.useState(false);

//   const handleScroll = () => {
//     if (window.scrollY > 0) {
//       setIsNavbarFixed(true);
//     } else {
//       setIsNavbarFixed(false);
//     }
//   };

//   React.useEffect(() => {
//     window.addEventListener('scroll', handleScroll);

//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);

//   const [isMenuOpen, setIsMenuOpen] = React.useState(false);

//   const handleMenuToggle = () => {
//     setIsMenuOpen(!isMenuOpen);
//   };

//   return (
//     <Box
//       as="nav"
//       boxShadow={useColorModeValue('sm', 'sm-dark')}
//       // Add a CSS class for the background blur effect
//       className={`sticky-navbar ${isNavbarFixed ? 'fixed' : ''}`}
//       // Add a CSS class for background blur effect
//       css={{
//         filter: 'auto',
//         backdropFilter: isNavbarFixed ? 'blur(4px)' : 'none',
//         backgroundColor: isNavbarFixed ? 'rgba(255, 255, 255, 0.8)' : 'transparent',
//         position: isNavbarFixed ? 'fixed' : 'relative',
//         top: isNavbarFixed ? '0' : 'auto',
//         left: '0',
//         right: '0',
//         zIndex: isNavbarFixed ? '100' : 'auto',
//       }}
//     >
//       <Container as="section" maxW="7xl" py={{ base: '3', lg: '4' }}>
//         <Flex justify="space-between" align="center">
//           <HStack spacing={4}>
//             <Link to="/" aria-label="Home">
//               <Image src={Logo} alt="Logo" height="14" width="auto" />
//             </Link>
//           </HStack>
//           {isDesktop ? (
//             <HStack spacing={4}>
//               {!isContactOrDirectorPath && (
//                 <>
//                   <LinkScroll to="NosMetiers" aria-label="NosMetiers" spy={true} smooth={true} offset={-70} duration={2000}>
//                     <Button variant={'solid'} colorScheme={'green'}>
//                       Nos Métiers
//                     </Button>
//                   </LinkScroll>
//                   <LinkScroll to="StatsOnBrand" aria-label="StatsOnBrand" spy={true} smooth={true} offset={-70} duration={2000}>
//                     <Button variant={'solid'} bgColor={'#2563eb'} color={'#fff'} _hover={{ bg: '#1f51aa' }}>
//                       Nos Chiffres Clés
//                     </Button>
//                   </LinkScroll>
//                   <Link to={`/contact`}>
//                   <Button bgColor={'#E2E8F0'} _hover={{ bg: '#CDD4E2' }}>
//                     Contactez-nous
//                   </Button>
//                 </Link>
//                 </>
//               )}
//               {isContactOrDirectorPath && (
//                 <Link to={`/contact`}>
//                   <Button bgColor={'#E2E8F0'} _hover={{ bg: '#CDD4E2' }}>
//                     Contactez-nous
//                   </Button>
//                 </Link>
//               )}
//             </HStack>
//           ) : (
//             <IconButton
//               variant="ghost"
//               icon={isMenuOpen ? <FiX fontSize="1.25rem" /> : <FiMenu fontSize="1.25rem" />}
//               aria-label="Toggle Menu"
//               onClick={handleMenuToggle}
//             />
//           )}
//         </Flex>
//         {isMenuOpen && (
//           <Box mt={4}>
//             <MobileMenu />
//           </Box>
//         )}
//       </Container>
//     </Box>
//   );
// };

// export default Navbar;







// import {
//   Avatar,
//   Box,
//   Button,
//   ButtonGroup,
//   Container,
//   Flex,
//   HStack,
//   IconButton,
//   Image,
//   useBreakpointValue,
//   useColorModeValue,
// } from '@chakra-ui/react';
// import * as React from 'react';
// import { FiMenu, FiX } from 'react-icons/fi';
// import MobileMenu from './MobileMenu';
// import { Link } from 'react-router-dom';
// import Logo from './LogoBelProfVfinal.svg';
// import { Link as LinkScroll} from 'react-scroll'


// const Navbar = () => {
//   const isDesktop = useBreakpointValue({
//     base: false,
//     lg: true,
//   });

//   const [isNavbarFixed, setIsNavbarFixed] = React.useState(false);

//   const handleScroll = () => {
//     if (window.scrollY > 0) {
//       setIsNavbarFixed(true);
//     } else {
//       setIsNavbarFixed(false);
//     }
//   };

//   React.useEffect(() => {
//     window.addEventListener('scroll', handleScroll);

//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);

//   const [isMenuOpen, setIsMenuOpen] = React.useState(false);

//   const handleMenuToggle = () => {
//     setIsMenuOpen(!isMenuOpen);
//   };

//   return (
//     <Box
//     as="nav"
//     boxShadow={useColorModeValue('sm', 'sm-dark')}
//     // Add a CSS class for the background blur effect
//     className={`sticky-navbar ${isNavbarFixed ? 'fixed' : ''}`}
//     // Add a CSS class for background blur effect
//     css={{
//       filter: 'auto' ,
//       backdropFilter: isNavbarFixed ? 'blur(4px)' : 'none',
//       backgroundColor: isNavbarFixed ? 'rgba(255, 255, 255, 0.8)' : 'transparent',
//       position: isNavbarFixed ? 'fixed' : 'relative',
//       top: isNavbarFixed ? '0' : 'auto',
//       left: '0',
//       right: '0',
//       zIndex: isNavbarFixed ? '100' : 'auto',
//     }}
//   >
//       <Container as="section" maxW="7xl" py={{ base: '3', lg: '4' }}>
//         <Flex justify="space-between" align="center">
//           <HStack spacing={4}>
//             <Link to="/" aria-label="Home">
//               <Image
//                 src={Logo}
//                 alt="Logo"
//                 height="14"
//                 width="auto"
//               />
//             </Link>
//           </HStack>
//           {isDesktop ? (
//             <HStack spacing={4}>
//               <ButtonGroup variant="ghost" spacing="1">

//               <LinkScroll to="NosMetiers" aria-label="NosMetiers" spy={true} smooth={true} offset={-70} duration={2000}>
//                   <Button variant={'solid'} colorScheme={'green'}>Nos Métiers</Button>
//               </LinkScroll>

//               <LinkScroll to="StatsOnBrand" aria-label="StatsOnBrand" spy={true} smooth={true} offset={-70} duration={2000}>
//                   <Button variant={'solid'} bgColor={"#2563eb"} color={"#fff"} _hover={{ bg: '#1f51aa' }}>Nos Chiffres Clés</Button>
//               </LinkScroll>

              
//                 <Link to={`/contact`}>
//                   <Button bgColor={"#E2E8F0"}  _hover={{ bg: '#CDD4E2' }}>Contactez-nous</Button>
//                 </Link>
//               </ButtonGroup>
//             </HStack>
//           ) : (
//             <IconButton
//               variant="ghost"
//               icon={isMenuOpen ? <FiX fontSize="1.25rem" /> : <FiMenu fontSize="1.25rem" />}
//               aria-label="Toggle Menu"
//               onClick={handleMenuToggle}
//             />
//           )}
//         </Flex>
//         {isMenuOpen && (
//           <Box mt={4}>
//             <MobileMenu />
//           </Box>
//         )}
//       </Container>
//     </Box>
//   );
// };

// export default Navbar;


