import React from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const BookForm = ({ carName, imageUrl,id, ...props }) => {
  const toast = useToast();
  const navigate = useNavigate();

  const handleDownload = () => {
    // Here, you can add your download logic using the `imageUrl`
    // For example, you can use Axios to download the image
    axios
      .get(imageUrl, { responseType: 'blob' })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `greenboys.art_${id}.png`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error('Error downloading image:', error);
      });
  };

  return (
    <form {...props}>
      <Stack  spacing={5} >
        <Box  display="flex" justifyContent="center" alignItems="center" >
        <Image src={imageUrl} alt={carName} />
        </Box>
        <Button
          type="button" // Use type "button" to prevent form submission
          colorScheme='orange'
          w="full"
          alignSelf="start"
          size="lg"
          onClick={handleDownload}
        >
          Download
        </Button>
      </Stack>
    </form>
  );
};

export default BookForm;

