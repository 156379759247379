
import React, { useEffect } from 'react';
import 'swiper/swiper-bundle.css';

import Navbar from '../../navbar/Navbar'
import Footer from '../../elements/Footer/Footer'
import Hero from './Hero'
import Newsletter from './Newsletter'
import ListStickers from './listStickers/ListStickers'

import './home.css'




const Home = () => {


  return (
    <>
        
        <Navbar/>
        <Hero />
        <ListStickers />
        <Footer/>
        
    </>
  )
}

export default Home