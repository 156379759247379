import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  IconButton,
  Input,
  Stack,
  Text,
  useColorModeValue,
  Image,
  AspectRatio,
  HStack,
  VStack
} from '@chakra-ui/react'
import * as React from 'react'
import { FaFacebook, FaInstagram, FaWhatsapp } from 'react-icons/fa'
import Logo from './LogoBelProfFooter.svg'
import { Link } from 'react-router-dom'
import { FiPhoneCall } from 'react-icons/fi'
import { AiOutlineMail } from 'react-icons/ai'

const Footer = () => (
  <Box bg={useColorModeValue('gray.50', 'gray.900')} color={useColorModeValue('gray.700', 'gray.200')}  marginTop={10}>
    <Container as="footer" maxW="7xl" role="contentinfo"  >
      <Divider borderColor="bg-accent-subtle" />
      <Stack
        pt="8"
        pb="12"
        justify="space-between"
        direction={{
          base: 'column-reverse',
          md: 'row',
        }}
        align="center"
      >
        <Text fontSize="sm" color="on-accent-subtle">
          &copy; {new Date().getFullYear()} GreenBoys.art , Inc. Tous droits réservés.
        </Text>
        <ButtonGroup variant="ghost-on-accent">
          <IconButton
            as="a"
            href="https://www.facebook.com/issamBS/"
            aria-label="LinkedIn"
            icon={<FaFacebook fontSize="1.25rem" />}
          />          
        </ButtonGroup>
      </Stack>
    </Container>
  </Box>
)

export default Footer;