import img8 from '../../../../assets/img/stickers/Gb2.png'
import img2 from '../../../../assets/img/stickers/Gb5.png'
import img9 from '../../../../assets/img/stickers/Gb3.png'
import img1 from '../../../../assets/img/stickers/Gb4.png'
import img5 from '../../../../assets/img/stickers/Gb.png'
import img6 from '../../../../assets/img/stickers/Gb6.png'
import img7 from '../../../../assets/img/stickers/Gb7.png'
import img3 from '../../../../assets/img/stickers/Gb8.png'
import img4 from '../../../../assets/img/stickers/Gb9.png'
import img10 from '../../../../assets/img/stickers/Gb10.png'
import img11 from '../../../../assets/img/stickers/Gb11.png'
import img12 from '../../../../assets/img/stickers/Gb12.png'


 
 

export const products = [
  {
    id: '-2',
    CarName: 'Stickers',
    imageUrl: img12,
    },
  {
    id: '-1',
    CarName: 'Stickers',
    imageUrl: img11,
    },
  {
    id: '0',
    CarName: 'Stickers',
    imageUrl: img10,
    },
  {
    id: '1',
    CarName: 'Stickers',
    imageUrl: img1,
    },
  {
    id: '2',
    CarName: 'Stickers',
    imageUrl: img2,
  },
  {
    id: '3',
    CarName: 'Stickers',
    imageUrl: img3,
  },
  {
    id: '4',
    CarName: 'Stickers',
    imageUrl: img4,
    },
  {
    id: '5',
    CarName: 'Stickers',
    imageUrl: img6,
  },
  {
    id: '6',
    CarName: 'Stickers',
    imageUrl: img8,
  },
  {
    id: '7',
    CarName: 'Stickers',
    imageUrl: img7,
  },
  {
    id: '8',
    CarName: 'Stickers',
    imageUrl: img5,
  },
  {
    id: '9',
    CarName: 'Stickers',
    imageUrl: img9,
  }
]







// import img1 from '../../../../assets/img/tags/domination.jpg'
// import img2 from '../../../../assets/img/stickers/root.png'
// import img3 from '../../../../assets/img/tags/aliens.jpg'



// export const products = [
//   {
//     id: '1',
//     CarName: 'Ferrari',
//     imageUrl:
//       'https://images.unsplash.com/photo-1583121274602-3e2820c69888?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80',
//     images,
//     },
//   {
//     id: '2',
//     CarName: 'Bmw',
//     imageUrl:
//       'https://images.unsplash.com/photo-1555215695-3004980ad54e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80',
    
//     images,
//   },
//   {
//     id: '3',
//     CarName: 'Audi',
//     imageUrl:
//       'https://images.unsplash.com/photo-1555652736-e92021d28a10?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80',
//     images,
//   }
// ]





