
import React, { useState } from 'react';
import {
  AspectRatio,
  Box,
  Button,
  HStack,
  Icon,
  Image,
  Skeleton,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import { FavouriteButton } from './FavouriteButton';
import BookNow from './bookNow/BookNow'; 
import { DownloadIcon, ViewIcon } from '@chakra-ui/icons';

export const ProductCard = (props) => {
  const { product, rootProps } = props;
  const { CarName, imageUrl, id } = product;
  const [isBookNowOpen, setIsBookNowOpen] = useState(false);

  const handleBookNowClick = () => {
    setIsBookNowOpen(true);
  };

  const color = useColorModeValue('gray.600', 'gray.300')

  return (
    <Stack
      spacing={useBreakpointValue({
        base: '4',
        md: '5',
      })}
      {...rootProps}
    >
      <Box  position="relative">
        <AspectRatio ratio={4 / 3}>
          <Image
            src={imageUrl}
            alt={CarName}
            draggable="false"
            fallback={<Skeleton />}
            borderRadius={useBreakpointValue({
              base: 'md',
              md: 'xl',
            })}
            height={"70vh"}
          />
        </AspectRatio>
        <FavouriteButton
          position="absolute"
          top="4"
          right="4"
          aria-label={`Add ${CarName} to your favourites`}
        />
      </Box>
      <Stack align="center">
      <Button
          rightIcon={<ViewIcon />}
          colorScheme='green'
          width="full"
          onClick={() => handleBookNowClick(CarName)} // Pass CarName here
          style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
        >
          <span style={{ marginRight: "5px" }}>View Art</span> {/* Adjust the margin as needed */}
        </Button>

      </Stack>

      {isBookNowOpen && <BookNow onClose={() => setIsBookNowOpen(false)} carName={CarName} imageUrl={imageUrl} id={id} />}
    </Stack>
  );
};



// import React, { useState } from 'react';
// import {
//   AspectRatio,
//   Box,
//   Button,
//   HStack,
//   Icon,
//   Image,
//   Skeleton,
//   Stack,
//   Text,
//   useBreakpointValue,
//   useColorModeValue as mode,
// } from '@chakra-ui/react';
// import { FavouriteButton } from './FavouriteButton';
// import { PriceTag } from './PriceTag';
// import BookNow from './bookNow/BookNow'; 
// import { MdAirlineSeatReclineExtra, MdArrowDropDown, MdSpeed } from 'react-icons/md';
// import { VscSettings } from 'react-icons/vsc';
// import { BsFillFuelPumpDieselFill } from 'react-icons/bs';

// export const ProductCard = (props) => {
//   const { product, rootProps } = props;
//   const { CarName, imageUrl, price, salePrice, nbPlaces, carburant, boiteVitesse, consomation } = product;
//   const color = mode('gray.600', 'gray.300'); // Call useColorModeValue unconditionally

//   const [isBookNowOpen, setIsBookNowOpen] = useState(false);
//   const [isKaiOpsOpen, setIsKaiOpsOpen] = useState(false);

//   const handleBookNowClick = () => {
//     setIsBookNowOpen(true);
//   };

//   const handleKaiOpsClick = () => {
//     setIsKaiOpsOpen(!isKaiOpsOpen);
//   };

//   return (
//     <Stack
//       spacing={useBreakpointValue({
//         base: '4',
//         md: '5',
//       })}
//       {...rootProps}
//     >
//       <Box position="relative">
//         <AspectRatio ratio={4 / 3}>
//           <Image
//             src={imageUrl}
//             alt={CarName}
//             draggable="false"
//             fallback={<Skeleton />}
//             borderRadius={useBreakpointValue({
//               base: 'md',
//               md: 'xl',
//             })}
//           />
//         </AspectRatio>
//         <FavouriteButton
//           position="absolute"
//           top="4"
//           right="4"
//           aria-label={`Add ${CarName} to your favourites`}
//         />
//       </Box>
//       <Stack>
//         <Stack
//           justify={'space-between'}
//           direction={{ base: 'row', md: 'row' }}
//           spacing="1"
//         >
//           <Text
//             fontWeight="medium"
//             color={mode('gray.700', 'gray.400')}
//           >
//             {CarName}
//           </Text>
//        <PriceTag price={price} salePrice={salePrice} currency="USD" />

//           <Icon as={MdArrowDropDown} fontSize="xl" onClick={handleKaiOpsClick} />
//         </Stack>
        
//         {isKaiOpsOpen && (
//           <Stack spacing="4" p="6" bg={mode('gray.50', 'gray.700')} {...props}>
//             <HStack justifyContent={'space-between'} spacing="3" color={color}>
//               <HStack>
//                 <Icon as={MdAirlineSeatReclineExtra} fontSize="xl" />
//                 <Text>{nbPlaces} Seats</Text>
//               </HStack>
//               <HStack>
//                 <Icon as={VscSettings} fontSize="xl" />
//                 <Text>{boiteVitesse}</Text>
//               </HStack>
//             </HStack>
//             <HStack justifyContent={'space-between'} spacing="3" color={color}>
//               <HStack>
//                 <Icon as={BsFillFuelPumpDieselFill} fontSize="xl" />
//                 <Text>{carburant}</Text>
//               </HStack>
//               <HStack>
//                 <Icon as={MdSpeed} fontSize="xl" />
//                 <Text>{consomation} L/100Km</Text>
//               </HStack>
//             </HStack>
//           </Stack>
//         )}
//       </Stack>
//       <Stack align="center">
//         <Button
//           bgColor="#2563eb"
//           color="#fff"
//           _hover={{ bg: '#1f51aa' }}
//           width="full"
//           onClick={() => handleBookNowClick(CarName)}
//         >
//           BOOK NOW
//         </Button>
//       </Stack>

//       {isBookNowOpen && <BookNow onClose={() => setIsBookNowOpen(false)} carName={CarName} />}
//     </Stack>
//   );
// };

